import React from "react";

export default function SimpleMap() {

  return (
    <iframe
      title="Google Maps Embed"
      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d332.90993216454234!2d-98.89460886336377!3d19.262964637447574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDE1JzQ2LjYiTiA5OMKwNTMnNDAuNiJX!5e0!3m2!1ses-419!2smx!4v1726353947246!5m2!1ses-419!2smx"
      width="100%"
      height="450"
      style={{ border: "0" }}
      allowfullscreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
}
