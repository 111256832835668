// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ServicesList() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <MKBadge
          variant="contained"
          color="primary"
          badgeContent="Servicios"
          container
          sx={{ mb: 2 }}
        />
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
            Tu bienestar es nuestra prioridad.
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
            Cada servicio está diseñado para ofrecerte una experiencia única y personalizada que te hará sentir renovado y rejuvenecido.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>

              <MKBox display="flex" alignItems="center" p={2} width="100%">
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MKBox display="flex" alignItems="center">
                      <Icon fontSize="small" sx={{ mr: 1 }}>spa</Icon>
                      <MKTypography variant="h6" color="text" pl={2}>
                        Tratamientos Faciales
                      </MKTypography>
                    </MKBox>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        <li style={{ marginBottom: '8px' }}>Limpieza Facial Profunda</li>
                        <li style={{ marginBottom: '8px' }}>Botox Antifaz</li>
                        <li style={{ marginBottom: '8px' }}>Dermaplaning</li>
                        <li style={{ marginBottom: '8px' }}>Radiofrecuencia Facial</li>
                        <li style={{ marginBottom: '8px' }}>Luz Pulsada para Acné</li>
                        <li style={{ marginBottom: '8px' }}>Luz Pulsada para Rejuvenecimiento Facial</li>
                        <li style={{ marginBottom: '8px' }}>Peeling Químico para Rejuvenecimiento</li>
                        <li style={{ marginBottom: '8px' }}>Peeling Químico Despigmentante</li>
                        <li style={{ marginBottom: '8px' }}>Plasma Rico en Plaquetas</li>
                        <li style={{ marginBottom: '8px' }}>Hidratación de Labios</li>
                        <li style={{ marginBottom: '8px' }}>Eliminación de Manchas con Plasmapen</li>
                        <li style={{ marginBottom: '8px' }}>Facial Hidratante</li>
                        <li style={{ marginBottom: '8px' }}>Rinomodelación</li>
                        <li style={{ marginBottom: '8px' }}>Carboxiterapia Facial</li>
                        <li style={{ marginBottom: '8px' }}>Tratamiento Antiacné</li>
                        <li style={{ marginBottom: '8px' }}>Paquete Rejuvenecimiento</li>
                      </ul>
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2} width="100%">
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MKBox display="flex" alignItems="center">
                      <Icon fontSize="small" sx={{ mr: 1 }}>spa</Icon>
                      <MKTypography variant="h6" color="text" pl={2}>
                        Tratamientos Estéticos
                      </MKTypography>
                    </MKBox>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Depilación IPL</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Colocación de Suero Vitaminado</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Aplicación de Inyección</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Aplicación de Medicamentos IV</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Aplicación de Suero</li>
                      </ul>
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              </MKBox>


              <MKBox display="flex" alignItems="center" p={2} width="100%">
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MKBox display="flex" alignItems="center">
                      <Icon fontSize="small" sx={{ mr: 1 }}>spa</Icon>
                      <MKTypography variant="h6" color="text" pl={2}>
                        Orientación y Cuidado Personal
                      </MKTypography>
                    </MKBox>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Orientación de Vida Saludable</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Orientación de Lactancia Materna y Cuidado del Recién Nacido</li>
                      </ul>
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              </MKBox>


              <MKBox display="flex" alignItems="center" p={2} width="100%">
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MKBox display="flex" alignItems="center">
                      <Icon fontSize="small" sx={{ mr: 1 }}>spa</Icon>
                      <MKTypography variant="h6" color="text" pl={2}>
                        Podología
                      </MKTypography>
                    </MKBox>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Uñas Encarnadas</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Hiperhidrosis (Sudoración Excesiva)</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Bromhidrosis (Mal Olor)</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Onicomicosis (Honguitos en las Uñas)</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Hiperqueratosis (Callosidades/Engrosamiento de Piel)</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Tratamientos Ortopédicos</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Limpieza de Pies</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Corte Correcto de Uñas (Onicotomía)</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Limpieza de Canales Ungueales</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Pulido de Callosidades y Uñas</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Exfoliación</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Masaje Podológico</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Recomendaciones para el Cuidado de los Pies</li>
                      </ul>
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              </MKBox>


            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ServicesList;