// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/CenteredFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/spa_images/logo_b.png";

function AboutUs() {
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://wa.me/525528575584?text=Hola%2C%20me%20gustaría%20agendar%20una%20cita",
            label: "Reservar Ahora",
            color: "primary",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center" mt={9}>
        <Grid item xs={12} lg={6}>
          <MKBox
            display="flex"
            width="calc(100% - 2rem)"
            height={{ xs: '40vh', lg: 'calc(76vh - 2rem)' }} // Ajusta la altura para móviles y escritorio
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{
              backgroundImage: `url(${bgImage})`,
              backgroundPosition: 'center', // Centra la imagen
              backgroundSize: 'cover', // Estira la imagen para cubrir el contenedor
              backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 10, sm: 9, md: 10 }}
            mb={{ xs: 10, sm: 9, md: 10 }}
            mx={3}
            p={2}
          >
            <MKBox
              variant="gradient"
              bgColor="primary"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Acerca de Nosotros
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                "Nos preocupamos por cada detalle para brindarte un espacio donde cada mujer pueda mimarse y relajarse.
                Enfatizamos la belleza natural sin necesidad de cirugía, ofreciendo una amplia gama de masajes y técnicas innovadoras adaptadas a cada necesidad. Creamos experiencias únicas que revitalizan el cuerpo, la mente y el espíritu, promoviendo una vida plena y en armonía."
              </MKTypography>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="center" mt={6}>
        <Grid item xs={12} sm={4}>
        <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mt={{ xs: 1, sm: 1, md: 1 }} // Reducir el margen superior
      mb={{ xs: 1, sm: 1, md: 1 }} // Reducir el margen inferior
      mx={2} // Reducir el margen horizontal
      p={2} // Reducir el padding
    >
            <MKBox
              variant="gradient"
              bgColor="primary"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mb={2}
            >
              <MKTypography variant="h5" color="white">
                Valores
              </MKTypography>
            </MKBox>
            <MKBox p={2}>
              <MKTypography variant="body2" color="text">
              Nos guiamos por la honradez, puntualidad y limpieza, combinando organización y eficiencia para ofrecer un servicio confiable y apasionado. Estos principios son la base de nuestra excelencia.
              </MKTypography>
            </MKBox>
          </MKBox>
        </Grid>
        <Grid item xs={12} sm={4}>
        <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mt={{ xs: 1, sm: 1, md: 1 }} // Reducir el margen superior
      mb={{ xs: 1, sm: 1, md: 1 }} // Reducir el margen inferior
      mx={2} // Reducir el margen horizontal
      p={2} // Reducir el padding
    >
            <MKBox
              variant="gradient"
              bgColor="primary"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mb={2}
            >
              <MKTypography variant="h5" color="white">
                Misión
              </MKTypography>
            </MKBox>
            <MKBox p={2}>
              <MKTypography variant="body2" color="text">
                Satisfacer las necesidades requeridas por el usuario mediante servicios
                de calidad y calidez, superando las expectativas de nuestros clientes              </MKTypography>
            </MKBox>
          </MKBox>
        </Grid>
        <Grid item xs={12} sm={4}>
        <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mt={{ xs: 1, sm: 1, md: 1 }} // Reducir el margen superior
      mb={{ xs: 1, sm: 1, md: 1 }} // Reducir el margen inferior
      mx={2} // Reducir el margen horizontal
      p={2} // Reducir el padding
    >
            <MKBox
              variant="gradient"
              bgColor="primary"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mb={2}
            >
              <MKTypography variant="h5" color="white">
                Visión
              </MKTypography>
            </MKBox>
            <MKBox p={2}>
              <MKTypography variant="body2" color="text">
                Estar a la vanguardia de las nuevas técnicas y tratamientos
                para brindar el mejor de los servicios
              </MKTypography>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
