

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import imageDemo from "assets/images/spa_images/logo_b.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="spa"
                    title="Experiencia Relajante"
                    description="¡Disfruta de una experiencia única y relajante donde te sentirás en completa paz y armonía!"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="build"
                    title="Servicios Integrales"
                    description="La tecnología, el profesionalismo y los avances científicos, son los tres pilares del servicio integral que ponemos a tu alcance."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="local_hospital"
                    title="Tratamientos Personalizados"
                    description="Elige el tratamiento que mejor se ajuste a tus necesidades."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="favorite"
                    title="Compromiso con tu Salud"
                    description="¡Nuestro compromiso es contigo y tu salud!"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={imageDemo}
              title="Compromiso con la Higiene y Seguridad"
              description="Además, la limpieza es muy importante en cualquiera de nuestros servicios. Por ello tenemos estrictos códigos de limpieza, desinfectamos nuestros equipos después de cada sesión, y nunca reciclamos material."
              action={{
                type: "external",
                route: "https://wa.me/525528575584?text=Hola%2C%20me%20gustaría%20agendar%20una%20cita",
                color: "primary",
                label: "agendar cita",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
