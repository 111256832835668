

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/examples/testimonial-6-2.jpg";
import post2 from "assets/images/examples/testimonial-6-3.jpg";
import post3 from "assets/images/examples/blog-9-4.jpg";
import post4 from "assets/images/examples/blog2.jpg";

import botox from "assets/images/spa_images/photo_1.jpg";
import depilacion from "assets/images/spa_time/depila.jpg";
import limpieza from "assets/images/spa_images/photo_4.jpg";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
          ¡Es momento de consentirte!
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={6}>
          En Spa Dulce Cielo,  te ofrecemos exclusivos tratamientos de spa en instalaciones cómodas y privadas, con equipos de vanguardia y productos efectivos. Nuestro equipo profesional se dedica a brindarte un servicio cálido y personalizado para tu bienestar.          </MKTypography>
          
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={limpieza}
              title="Limpieza Facial Profunda"
              description="Es fundamental para mantener la piel limpia y libre de impurezas, ayudando a prevenir problemas como el acné y mejorando la apariencia general de la piel."
              action={{
                type: "internal",
                route: "https://wa.me/525528575584?text=Hola%2C%20me%20gustaría%20agendar%20una%20cita",
                color: "primary",
                label: "Reservar",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={botox}
              title="Botox Antifaz"
              description="Muy popular para reducir las arrugas y líneas de expresión en la zona de la frente y alrededor de los ojos, proporcionando un aspecto más juvenil."
              action={{
                type: "internal",
                route: "link",
                color: "primary",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={depilacion}
              title="Depilación IPL:"
              description="Ofrece una solución a largo plazo para la reducción del vello, siendo eficaz y menos dolorosa que otros métodos de depilación."
              action={{
                type: "internal",
                route: "link",
                color: "primary",
                label: "read more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
