/* eslint-disable react/react-in-jsx-scope */
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";

// Material Kit 2 React components
// eslint-disable-next-line no-unused-vars
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/logo.png";

const date = new Date().getFullYear();


export default {
  brand: {
    name: "Dulce Cielo",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/Erendiracosmetologa",
    },
    {
      icon: <Instagram />,
      link: "https://www.instagram.com/spadulcecielo",
    },
  ],
  menus: [
    {
      name: "",
      items: [],
    },
    {
      name: "",
      items: [],
    },
    {
      name: "",
      items: [],
    },
    {
      name: "",
      items: [],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      &copy; {date} Spa Dulce Cielo
      <MKTypography
        component="a"
        href=""
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
      </MKTypography>
    </MKTypography>
  ),
};
