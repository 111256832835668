

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/spa_images/dulce.jpg";
import team2 from "assets/images/spa_images/woman.jpeg";
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Nuestro Equipo
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
            Cada desafío es una oportunidad para perfeccionar nuestras habilidades y ofrecer lo mejor a nuestros clientes.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Dulce Arellano"
                position={{ color: "info", label: "Lic en enfermería y Cosmetologa." }}
                description="Especialista en el cuidado de la piel y la belleza, dedicada a mejorar y mantener la salud y apariencia de la piel. Con un enfoque personalizado, brinda tratamientos faciales, rejuvenecimiento y técnicas avanzadas para resaltar tu belleza natural."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Karla Cantero Pacheco"
                position={{ color: "info", label: "Podóloga" }}
                description="Profesional experta en la salud de los pies, comprometida a prevenir, diagnosticar y tratar diversas afecciones podológicas. Ofrece soluciones efectivas para el bienestar y confort de tus pies, asegurando un cuidado integral y especializado."
              />
            </MKBox>
          </Grid>
{/*           <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Ivana Flow"
                position={{ color: "info", label: "Athlete" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Marquez Garcia"
                position={{ color: "info", label: "JS Developer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
